var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "competition h-295px my-2 px-4",
      class: _vm.data.block,
      attrs: { id: "competition-type5" },
    },
    [
      _c(
        "swiper",
        {
          ref: "slider",
          staticClass: "swiper h-100 rounded box-shadow",
          attrs: { options: _vm.swiperOption },
        },
        _vm._l(_vm.filterCompetitionItem, function (competition, index) {
          return _c(
            "swiper-slide",
            { key: competition._id, staticClass: "card1 overflow-y-auto" },
            [
              _c(
                "div",
                { staticClass: "competion__title d-flex px-4 py-2 mt-2" },
                [
                  _c("v-img", {
                    attrs: {
                      "max-width": "20",
                      "max-height": "20",
                      src: competition[0].game_logo,
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "caption title--text ml-2 font-weight-bold",
                    },
                    [_vm._v(_vm._s(index))]
                  ),
                ],
                1
              ),
              _vm._l(competition, function (item) {
                return _c(
                  "div",
                  { key: item._id },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "competion__content d-flex justify-space-between align-center px-4 h-68px",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "competion__content-home w-30 d-flex align-center",
                          },
                          [
                            _c("v-img", {
                              attrs: {
                                "max-width": "32",
                                "max-height": "32",
                                src: item.home.team_logo,
                              },
                            }),
                            _c(
                              "span",
                              { staticClass: "caption primary--text ml-2" },
                              [_vm._v(_vm._s(item.home.team_name))]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "competion__content-num warning--text font-weight-bold text-center",
                          },
                          [
                            _c("div", { staticClass: "secondary--text" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.start_datetime
                                      .split(" ")[1]
                                      .slice(0, 5)
                                  ) +
                                  " "
                              ),
                            ]),
                            _c(
                              "div",
                              { staticClass: "caption subtitle--text" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(item.start_datetime.split(" ")[0]) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "competion__content-away w-30 d-flex justify-end align-center text-right",
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "caption primary--text mr-2" },
                              [_vm._v(_vm._s(item.away.team_name))]
                            ),
                            _c("v-img", {
                              attrs: {
                                "max-width": "32",
                                "max-height": "32",
                                src: item.away.team_logo,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c("v-divider", { staticClass: "divider mx-4" }),
                  ],
                  1
                )
              }),
            ],
            2
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }