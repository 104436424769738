<template>
  <div
    id="competition-type5"
    :class="data.block"
    class="competition h-295px my-2 px-4"
  >
    <swiper
      ref="slider"
      class="swiper h-100 rounded box-shadow"
      :options="swiperOption"
    >
      <swiper-slide
        v-for="(competition, index) in filterCompetitionItem"
        :key="competition._id"
        class="card1 overflow-y-auto"
      >
        <!-- 比賽名稱 -->
        <div class="competion__title d-flex px-4 py-2 mt-2">
          <v-img
            max-width="20"
            max-height="20"
            :src="competition[0].game_logo"
          />
          <span class="caption title--text ml-2 font-weight-bold">{{ index }}</span>
        </div>

        <!-- 列表 -->
        <div
          v-for="item in competition"
          :key="item._id"
        >
          <!-- 比賽內容 -->
          <div class="competion__content d-flex justify-space-between align-center px-4 h-68px">
            <!-- 主場 -->
            <div class="competion__content-home w-30 d-flex align-center">
              <v-img
                max-width="32"
                max-height="32"
                :src="item.home.team_logo"
              />
              <span class="caption primary--text ml-2">{{ item.home.team_name }}</span>
            </div>

            <!-- 時間 / 日期-->
            <div class="competion__content-num warning--text font-weight-bold text-center">
              <div class="secondary--text">
                {{ item.start_datetime.split(' ')[1].slice(0,5) }}
              </div>
              <div class="caption subtitle--text">
                {{ item.start_datetime.split(' ')[0] }}
              </div>
            </div>

            <!-- 客場 -->
            <div class="competion__content-away w-30 d-flex justify-end align-center text-right">
              <span class="caption primary--text mr-2">{{ item.away.team_name }}</span>
              <v-img
                max-width="32"
                max-height="32"
                :src="item.away.team_logo"
              />
            </div>
          </div>
          <v-divider class="divider mx-4" />
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data: () => ({}),

  // 賽事資料
  computed: {
    ...mapGetters(['baseImgUrl', 'theme']),

    competitions() {
      return this.data.data[this.data.type]
    },

    filterCompetitionName() {
      const competionNameAry = []
      this.data.data[this.data.type].forEach(item => {
        if (competionNameAry.includes(item.competition_name)) return false
        competionNameAry.push(item.competition_name)
      })
      return competionNameAry
    },

    filterCompetitionItem() {
      const competitionItems = {}
      this.filterCompetitionName.forEach(name => {
        competitionItems[name] = this.competitions.filter(item => item.competition_name === name)
      })
      return competitionItems
    },

    swiperOption() {
      return {
        autoplay: {
          delay: 3000,
        },
      }
    },

    // item background
    bg() {
      return {
        light: { background: 'rgba(206, 206, 206, 0.12)' },
        dark: { background: 'rgba(0, 0, 0, 0.25)' },
      }
    },

    customPaginationColor() {
      const themePrimaryColor = this.$vuetify.theme.themes[this.theme]?.primary
      // 目前依 primary 顯色
      return {
        '--swiper-theme-color': themePrimaryColor || 'white',
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.competition {
	// overflow-y: auto;
}

.h-295px {
	height: 295px;
}

.h-68px {
	height: 68px;
}

.w-30 {
	width: 30%;
}

.competion__content {
	&-home,
	&-away {
		.caption {
			line-height: 14px;
		}
	}
}

.swiper {
	.swiper-pagination {
		top: 4px;
		margin-left: -16px;
		text-align: right;
	}
}

.box-shadow {
	box-shadow: 5px 5px 20px rgba(0, 0, 0, .2);
}
</style>
